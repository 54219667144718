<!--
    @name: WorkTodo
    @description：WorkTodo
    @author: ZengWei
    @date: 2022-05-27 14:05
-->
<template>
  <div class="work-todo">
    <div class="container">
      <div class="header" >
        <el-button @click="goback" style="margin-left: 10px" icon="el-icon-arrow-left" circle size="mini"></el-button>
        <span class="text">发布工单</span>
      </div>
      <div class="wrapper">
        <div class="designer">
          <div class="header-bg"></div>
          <TaskDesigner @on-save="triggerSubmit">
            <PcParser
              ref="workParser"
              v-if="formDesignData"
              :form-data="formDesignData"
            ></PcParser>
          </TaskDesigner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PcParser from "@/custom-component/form/newParser/PcParser.vue";
import TaskDesigner from "@/views/workOrder/task-flow/TaskDesigner.vue";
import {dataInterface, transFieldsUUid} from "@/apis/data";
export default {
  name: "WorkTodo",
  components: {
    TaskDesigner,
    PcParser
  },
  props: {},
  data() {
    return {
      formDesignData: null,
      taskEngineId: 0,
      contentConfig:{
        objectUUID: 'object62946b8979e52',
        formUUID: 'form629470323f11f',
      },
      type: this.$route.query.type || 'createData',
    }
  },
  mounted() {
    this.initFormDesign(this.contentConfig)
  },
  methods: {
    goback(){
      this.$router.go(-1)
    },
    triggerValidate(){
      this.$refs.workParser.validateFormInput()
    },
    triggerSubmit(saveData){
      if(this.taskEngineId){
        const formData = this.$refs.workParser.getFormData()
        formData.task_engine_id = this.taskEngineId
        const param = {
          __method_name__: 'createData',
          object_uuid: 'object62946b8979e52',
          transcode: 0,
          ...formData
        }
        dataInterface(param).then(res=>{
          if(res.data.code === 200){
            this.$message.success(res.data.msg)
            this.$router.go(-1)
          }
        })
      } else {
        dataInterface(saveData).then(res=>{
          if(res.data.code === 200){
            const taskEngineId = res.data.data.id
            this.taskEngineId = taskEngineId
            if(!taskEngineId){
              this.$message.error('任务引擎ID不存在')
              return
            }
            const formData = this.$refs.workParser.getFormData()
            formData.task_engine_id = taskEngineId
            const param = {
              __method_name__: 'createData',
              object_uuid: 'object62946b8979e52',
              transcode: 0,
              ...formData
            }
            dataInterface(param).then(res=>{
              if(res.data.code === 200){
                this.$message.success(res.data.msg)
                this.$router.go(-1)
              }
            })
          }
        })
      }
    },
    /**
     * @desc: 初始化表单
     */
    initFormDesign(contentConfig) {
      if (!contentConfig) return;
      this.loading = true;
      transFieldsUUid(contentConfig.objectUUID, contentConfig.formUUID).then((res) => {
        if (res.data.code == 200) {
          // 编辑或详情
          if (this.type === 'updateData' || this.type === 'dataInfo') {
            // 编辑
            // 获取已经有的数据进行绑定
            let config = res.data.data;
            let ruuids = [];
            for (let i = 0; i < config.fields.length; i++) {
              let item = config.fields[i];
              if (item.__config__.tagIcon == 'form') {
                ruuids.push({ relationship_uuid: item.__vModel__ });
              }
            }
            dataInterface({
              object_uuid: contentConfig.objectUUID,
              view_uuid: '',
              ruuids,
              __method_name__: 'dataInfo'
            }).then((res1) => {
              let data = res1.data.data;
              this.formDesignData = res.data.data;
              this.excelFormData = {
                [contentConfig.objectUUID]: JSON.parse(JSON.stringify(data))
              };
              this.excelMetaData = JSON.parse(JSON.stringify(res1.data.metadata));
              this.displayData = data;
              if (data.editFields instanceof Array) {
                this.editFields = {};
              } else {
                this.editFields = data.editFields || {};
              }
              this.loading = false;
            });
          } else{
            this.formDesignData = res.data.data;
          }
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
  },
}
</script>

<style lang="less" scoped>
  :deep(.work-todo) {
    width: 100%;
    height: 100%;

    .el-button--mini.is-circle {
      padding: 4px;
    }

    .header{
      height: 40px;
      background-color: #fff;

      .text{
        padding-left: 10px;
        line-height: 40px;
      }
    }

    .container{
      display: flex;
      height: 100%;
      flex-direction: column;
    }

    .wrapper{
      flex: 1;
      background-color: #F2F4F7;
      overflow: hidden;
      padding-top: 10px;

      .designer {
        width: 80%;
        height: 100%;
        margin: auto;
        border-radius: 10px 10px 0 0;
        background-color: #fff;

        .header-bg{
          height: 48px;
          border-radius: 10px 10px 0 0;
          //background-image: linear-gradient(-225deg, #B6CEE8 0%, #F578DC 100%);
          background-image: url('~@/views/workOrder/components/img/cardbackground.png');
          background-size: cover;
        }
      }
    }
  }
</style>
